import MiddlewareClient from "./MiddlewareClient"

export enum Context {
    Auth0HostedUI
}

export enum ActionTypes {

    SIGNUP_WITH_EMAIL_AND_MOBILE,
    SIGNUP_WITH_EMAIL_MOBILE_AND_CARD,
    LOGIN_WITH_EMAIL_PASSWORD,
    LOGIN_WITH_SMS_OTP,
    LOGIN_WITH_LINE,
    SIGNUP_WITH_APPLE,
    SHOW_LOGIN,
    SHOW_SIGNUP,
    SHOW_FORGOT_PASSWORD,
    SUBMIT_FORGOT_PASSWORD,
    LOGIN_WITH_CARD,
    VERIFY_SMS_OTP,
    LOOKUP_FOR_CARD,
    SUBMIT_REG_INFO_CARD,
    LOGIN_WITH_GOOGLE,
    LOGIN_WITH_CARD_PASSWORD,
}

export enum Steps {
    SIGNUP,
    SIGNUP_WITH_CARD,
    LOGIN,
    SMS_OTP_VERIFY,
    REGISTRATION,
    CARD,
    CARD_INFO,
    FORGOT_PASSWORD,
}

export type Payload = Partial<{
    email: string,
    otp: string,
    mobile: string
    name: string,
    phone: string,
    address: string,
    dob: string,
    card: string,
    firstname: string,
    lastname: string,
    password: string
}>

export type DispatchAction = (actionType: ActionTypes, payload?: Payload) => void

export interface State {
    context: Context
    currentStep: Steps
    nextAction?: ActionTypes
    auth0Client: auth0.WebAuth | undefined
    middlewareClient: MiddlewareClient | undefined
    message: string
    email?: string | undefined
    mobile?: string | undefined
    dob?: string | undefined
    card?: string | undefined
    firstname?: string | undefined
    lastname?: string | undefined
    card_email?: string | undefined
    card_mobile?: string | undefined
    card_katakana_sei?: string | undefined
    card_katakana_mei?: string | undefined
    card_address?: string | undefined
}
