import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ActionTypes, DispatchAction, State } from '../lib/types';
import Alert from '@mui/material/Alert';

const defaultTheme = createTheme();

interface Props {
    state: State
    dispatchAction: DispatchAction
}

export default function SignIn(props: Props) {

    const { dispatchAction, state } = props

    const handleEmailPwSubmit = (event: React.FormEvent<HTMLFormElement>, dispatchAction: DispatchAction) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatchAction(ActionTypes.LOGIN_WITH_EMAIL_PASSWORD, {
            email: data.get('email')?.toString()!,
            password: data.get('password')?.toString()!,
        })
    };

    const handleMobileOtpSubmit = (event: React.FormEvent<HTMLFormElement>, dispatchAction: DispatchAction) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatchAction(ActionTypes.LOGIN_WITH_SMS_OTP, {
            mobile: data.get('mobile')?.toString()!,
        })
    };


    const handleCardSubmit = (event: React.FormEvent<HTMLFormElement>, dispatchAction: DispatchAction) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatchAction(ActionTypes.LOGIN_WITH_CARD, {
            email: data.get('email')?.toString()!,
            password: data.get('password')?.toString()!,
        })
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Login Demo
                    </Typography>
                    <Box component="form" noValidate onSubmit={(e) => handleEmailPwSubmit(e, dispatchAction)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            {state.message && (
                                <Grid item xs={12}>
                                    <Alert variant="outlined" severity="info" >{state.message}</Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5">
                                    Email or Card Number
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Email Address or Card Number"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    name="password"
                                    autoComplete="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    sx={{ mb: 2 }}
                                >
                                    Sign In
                                </Button>
                            </Grid>

                        </Grid>
                    </Box>
                    <Box component="form" noValidate onSubmit={(e) => handleMobileOtpSubmit(e, dispatchAction)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5">
                                    Mobile OTP
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="mobile"
                                    label="Mobile Number"
                                    name="mobile"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    sx={{ mb: 2 }}
                                >
                                    Sign In
                                </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5">
                                    Social
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    sx={{ mb: 2 }}
                                    onClick={() => dispatchAction(ActionTypes.LOGIN_WITH_LINE)}
                                >
                                    LINE
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    sx={{ mb: 2 }}
                                >
                                    Apple
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="secondary"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() => dispatchAction(ActionTypes.SHOW_SIGNUP)}
                                >
                                    Don't have an account? Sign Up
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="secondary"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={() => dispatchAction(ActionTypes.SHOW_FORGOT_PASSWORD)}
                                >
                                    Forgot Password? Password Reset 
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    );
}