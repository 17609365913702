import * as auth0 from 'auth0-js';
import * as React from 'react';
import SignUp from './components/SignUp';
import { useEffect, useState } from 'react';
import SignIn from './components/SignIn';
import { ActionTypes, Context, Payload, State, Steps } from './lib/types';
import SmsOtpVerify from './components/SmsOtpVerify';
import MiddlewareClient from './lib/MiddlewareClient';
import ForgotPassword from './components/ForgotPassword';

const config = require('./config.json')

declare global {
  interface Window { config: any; }
}

const initAuth0Client = () => {

  const config = window.config

  var leeway = config.internalOptions.leeway;
  if (leeway) {
    var convertedLeeway = parseInt(leeway);

    if (!isNaN(convertedLeeway)) {
      config.internalOptions.leeway = convertedLeeway;
    }
  }

  var params = Object.assign({
    overrides: {
      __tenant: config.auth0Tenant,
      __token_issuer: config.authorizationServer.issuer
    },
    domain: config.auth0Domain,
    clientID: config.clientID,
    redirectUri: config.callbackURL,
    responseType: 'code'
  }, config.internalOptions);

  return new auth0.WebAuth(params);
}

const initCapillaryClient = () => {
  return new MiddlewareClient();
}

export default function App() {

  const [state, setState] = useState<State>({
    context: Context.Auth0HostedUI,
    currentStep: Steps.SIGNUP,
    message: "",
    auth0Client: undefined,
    middlewareClient: undefined,
    email: undefined,
    mobile: undefined
  })

  useEffect(() => {
    if (window.config) {
      const auth0Client = initAuth0Client();
      const middlewareClient = initCapillaryClient();
      setState({ ...state, auth0Client, middlewareClient })
    }
  }, [window.config])

  const dispatchAction = async (actionType: ActionTypes, payload?: Payload) => {
    const auth0Client = state.auth0Client!
    const middlewareClient = state.middlewareClient!

    switch (actionType) {
      case ActionTypes.SHOW_SIGNUP: {
        setState({
          ...state, currentStep: Steps.SIGNUP
        })
        break
      }
      case ActionTypes.SHOW_LOGIN: {
        setState({
          ...state, currentStep: Steps.LOGIN
        })
        break
      }
      case ActionTypes.LOGIN_WITH_EMAIL_PASSWORD: {
        auth0Client.login({
          realm: config.PRIMARY_CONNECTION, email: payload?.email, password: payload?.password!,
        }, (err) => {
          console.log(err)
          if (err) {
            setState({
              ...state,
              message: err.description || ""
            })
          }
        })
        break
      }
      case ActionTypes.LOGIN_WITH_CARD_PASSWORD: {
        auth0Client.login({
          realm: config.PRIMARY_CONNECTION, email: payload?.card, password: payload?.password!,
        }, (err) => {
          console.log(err)
          if (err) {
            setState({
              ...state,
              message: err.description || ""
            })
          }
        })
        break
      }
      case ActionTypes.LOGIN_WITH_LINE: {
        auth0Client.authorize({ connection: 'line' });
        break
      }
      case ActionTypes.LOGIN_WITH_GOOGLE: {
        auth0Client.authorize({ connection: 'google-oauth2' });
        break
      }
      case ActionTypes.LOGIN_WITH_SMS_OTP: {
        auth0Client.passwordlessStart({ connection: "sms", phoneNumber: payload?.mobile, send: 'code' }, (err) => {
          setState({
            ...state,
            mobile: payload?.mobile,
            message: (err && JSON.stringify(err)) || "",
            currentStep: Steps.SMS_OTP_VERIFY
          })
        });
        break
      }
      case ActionTypes.VERIFY_SMS_OTP: {
        auth0Client.passwordlessVerify({
          connection: 'sms',
          phoneNumber: state.mobile,
          verificationCode: payload?.otp!
        }, (err) => {
          setState({
            ...state,
            message: (err && JSON.stringify(err)) || "",
          })
        });
        break
      }
      case ActionTypes.SIGNUP_WITH_EMAIL_AND_MOBILE: {
        auth0Client.signup({
          email: payload?.email!,
          username: "_" + Date.now(),
          password: payload?.password!,
          connection: config.PRIMARY_CONNECTION,
          userMetadata: {
            "mobile": "+81" + payload?.mobile
          }
        }, (err) => {
          if (err) {
            console.log(err)
            setState({
              ...state,
              message: (err && JSON.stringify(err.original.response.body.data)) || ""
            })
          } else {
            // if sign up was successfu, proceed with login
            dispatchAction(ActionTypes.LOGIN_WITH_EMAIL_PASSWORD, {
              email: payload?.email,
              password: payload?.password
            })
          }
        })
        break
      }
      case ActionTypes.LOOKUP_FOR_CARD: {
        try {
          console.log('here!')
          const { user } = await middlewareClient.lookupCardInfo(
            payload?.card!,
            payload?.firstname!,
            payload?.lastname!,
            payload?.dob!
          )
          setState({
            ...state,
            currentStep: Steps.SIGNUP_WITH_CARD,
            card: payload?.card,
            firstname: payload?.firstname,
            lastname: payload?.lastname,
            dob: payload?.dob,
            card_address: user?.address,
            card_katakana_mei: user?.katakana_mei,
            card_katakana_sei: user?.katakana_sei,
            card_email: user?.email,
            card_mobile: user?.mobile,
          })
        } catch (e) {
          console.log(e)
          setState({
            ...state,
            message: "Error. Please see the console log"
          })
        }
        break
      }
      case ActionTypes.SIGNUP_WITH_EMAIL_MOBILE_AND_CARD: {
        try {
          auth0Client.signup({
            email: state.card_email!,
            username: state.card || payload?.email,
            password: payload?.password!,
            connection: config.PRIMARY_CONNECTION,
            userMetadata: {
              mobile: "+81" + payload?.mobile,
              firstName: state.firstname,
              lastName: state.lastname,
              dob: state.dob,
              address: state.dob,
            }
          }, (err) => {
            if (err) {
              console.log(err)
              setState({
                ...state,
                message: err?.original?.response?.body && JSON.stringify(err.original.response.body)
              })
            } else {
              // if sign up was successfu, proceed with login
              dispatchAction(ActionTypes.LOGIN_WITH_CARD_PASSWORD, {
                card: state.card,
                password: payload?.password
              })
            }
          })
        } catch (e) {
          setState({
            ...state,
            message: "Failed to sign up",
          })
        }
        break
      }
      case ActionTypes.SHOW_FORGOT_PASSWORD: {
        setState({
          ...state,
          currentStep: Steps.FORGOT_PASSWORD
        })
        break
      }
      case ActionTypes.SUBMIT_FORGOT_PASSWORD: {
        try {
          auth0Client.changePassword({
            email: payload?.email!,
            connection: config.PRIMARY_CONNECTION
          }, (err) => {
            if (err) {
              console.log(err)
              setState({
                ...state,
                message: err?.original?.response?.body && JSON.stringify(err.original.response.body)
              })
            } else {
              setState({
                ...state,
                message: "An email has been sent to your email address. Please check your email"
              })
            }
          })
        } catch (e) {
          setState({
            ...state,
            message: "Failed to process Forgot Password",
          })
        }

        break
      }
      default: {
        throw Error(`Undefined action type: ${actionType}`)
      }
    }
  }

  if (!state.auth0Client || !state.middlewareClient) {
    return <h1>Initializing...</h1>
  }

  switch (state.currentStep) {
    case Steps.SIGNUP: { return <SignUp dispatchAction={dispatchAction} state={state} /> }
    case Steps.SIGNUP_WITH_CARD: { return <SignUp dispatchAction={dispatchAction} state={state} /> }
    case Steps.LOGIN: { return <SignIn dispatchAction={dispatchAction} state={state} /> }
    case Steps.SMS_OTP_VERIFY: { return <SmsOtpVerify dispatchAction={dispatchAction} state={state} /> }
    case Steps.FORGOT_PASSWORD: { return <ForgotPassword dispatchAction={dispatchAction} state={state} /> }
    default: { return <div>Step {state.currentStep} not implemented yet.</div> }
  }

}
