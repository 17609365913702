import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ActionTypes, DispatchAction, State } from '../lib/types';
import Alert from '@mui/material/Alert';

const defaultTheme = createTheme();

interface Props {
    state: State
    dispatchAction: DispatchAction
}

export default function ForgotPassword(props: Props) {

    const { dispatchAction, state } = props

    const handleForgotPasswordSubmit = (event: React.FormEvent<HTMLFormElement>, dispatchAction: DispatchAction) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatchAction(ActionTypes.SUBMIT_FORGOT_PASSWORD, {
            email: data.get('email')?.toString()!
        })
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Password Reset Demo
                    </Typography>
                    <Box component="form" noValidate onSubmit={(e) => handleForgotPasswordSubmit(e, dispatchAction)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            {state.message && (
                                <Grid item xs={12}>
                                    <Alert variant="outlined" severity="info" >{state.message}</Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography component="h1" variant="h5">
                                    Email
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    color="secondary"
                                    variant="contained"
                                    sx={{ mb: 2 }}
                                >
                                    Send Reset Password Email
                                </Button>
                            </Grid>

                        </Grid>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="text"
                                color="secondary"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => dispatchAction(ActionTypes.SHOW_SIGNUP)}
                            >
                                Don't have an account? Sign Up
                            </Button>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    );
}