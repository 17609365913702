import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ActionTypes, DispatchAction, State, Steps } from '../lib/types';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const defaultTheme = createTheme();

interface Props {
    dispatchAction: DispatchAction
    state: State
}

export default function SignUp(props: Props) {

    const { dispatchAction, state } = props

    const handleEmailMobileSubmit = (event: React.FormEvent<HTMLFormElement>, dispatchAction: DispatchAction) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatchAction(ActionTypes.SIGNUP_WITH_EMAIL_AND_MOBILE, {
            email: data.get('email')?.toString()!,
            mobile: data.get('mobile')?.toString()!,
            password: data.get('password')?.toString()!,
        })
    };

    const handleCardSubmit = (event: React.FormEvent<HTMLFormElement>, dispatchAction: DispatchAction) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        console.log('here')

        if (state.currentStep === Steps.SIGNUP) {

            dispatchAction(ActionTypes.LOOKUP_FOR_CARD, {
                card: data.get('card')?.toString()!,
                firstname: data.get('fn')?.toString()!,
                lastname: data.get('ln')?.toString()!,
                dob: data.get('dob')?.toString()!,
            })

        } else {

            dispatchAction(ActionTypes.SIGNUP_WITH_EMAIL_MOBILE_AND_CARD, {
                email: data.get('email')?.toString()!,
                mobile: data.get('mobile')?.toString()!,
                password: data.get('password')?.toString()!,
            })

        }

    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="lg">
                <CssBaseline />

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Signup Demo
                    </Typography>
                    {
                        state.message && (
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                {state.message}
                            </Alert>
                        )
                    }

                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item xs={6}>
                            <Paper elevation={0} style={{ textAlign: "center" }}>
                                <Box component="form" noValidate onSubmit={(e) => handleEmailMobileSubmit(e, dispatchAction)} sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography component="h1" variant="h5">
                                                Email & Mobile
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="email"
                                                defaultValue="john+10@gmail.com"
                                                label="Email Address"
                                                name="email"
                                                autoComplete="email"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                defaultValue="08077043411"
                                                id="mobile"
                                                label="Mobile Number"
                                                name="mobile"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                required
                                                defaultValue="Tra!nme4321"
                                                type='password'
                                                id="password"
                                                label="Password"
                                                name="password"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                sx={{ mb: 2 }}
                                            >
                                                Sign Up
                                            </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography component="h1" variant="h5">
                                                Social
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ mb: 2 }}
                                                style={{ backgroundColor: "yellowgreen" }}
                                                onClick={() => dispatchAction(ActionTypes.LOGIN_WITH_LINE)}
                                            >
                                                LINE
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ mb: 2 }}
                                                style={{ backgroundColor: "white", color: "black" }}
                                                onClick={() => dispatchAction(ActionTypes.LOGIN_WITH_GOOGLE)}
                                            >
                                                Google
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ mb: 2 }}
                                                style={{ backgroundColor: "black" }}
                                            >
                                                Apple
                                            </Button>
                                        </Grid>


                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper elevation={0} style={{ textAlign: "center" }}>
                                <Box component="form" noValidate onSubmit={(e) => handleCardSubmit(e, dispatchAction)} sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography component="h1" variant="h5">
                                                Card Number
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                state.currentStep === Steps.SIGNUP ?
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="card"
                                                        defaultValue="1234"
                                                        label="Card Number"
                                                        name="card"
                                                        autoComplete="card"
                                                    />
                                                    :
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        defaultValue={state.card}
                                                        id="card"
                                                        label="Card Number"
                                                        name="card"
                                                        autoComplete="card"
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                state.currentStep === Steps.SIGNUP ?
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="fn"
                                                        defaultValue="Yu"
                                                        label="First Name"
                                                        name="fn"
                                                    />
                                                    :
                                                    <TextField
                                                        disabled
                                                        defaultValue={state.firstname}
                                                        fullWidth
                                                        id="fn"
                                                        label="First Name"
                                                        name="fn"
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                state.currentStep === Steps.SIGNUP ?
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="ln"
                                                        defaultValue="Sasaki"
                                                        label="Last Name"
                                                        name="ln"
                                                    />
                                                    :
                                                    <TextField
                                                        disabled
                                                        defaultValue={state.lastname}
                                                        fullWidth
                                                        id="ln"
                                                        label="Last Name"
                                                        name="ln"
                                                    />
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                state.currentStep === Steps.SIGNUP ?
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        id="dob"
                                                        defaultValue="2024/01/01"
                                                        label="Date of Birth"
                                                        name="dob"
                                                    />
                                                    :
                                                    <TextField
                                                        disabled
                                                        defaultValue={state.dob}
                                                        fullWidth
                                                        id="dob"
                                                        label="Date of Birth"
                                                        name="dob"
                                                    />
                                            }
                                        </Grid>
                                        {
                                            state.currentStep === Steps.SIGNUP_WITH_CARD &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography component="h1" variant="h6">
                                                        Information pulled from Capillary
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled
                                                        defaultValue={state.card_katakana_sei}
                                                        fullWidth
                                                        id="katakana_sei"
                                                        label="Katakana Sei (Fetched from Capillary)"
                                                        name="katakana_sei"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled
                                                        defaultValue={state.card_katakana_mei}
                                                        fullWidth
                                                        id="katakana_mei"
                                                        label="Katakana Mei (Fetched from Capillary)"
                                                        name="katakana_mei"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        disabled
                                                        defaultValue={state.card_address}
                                                        fullWidth
                                                        id="address"
                                                        label="Address (Fetched from Capillary)"
                                                        name="address"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography component="h1" variant="h6">
                                                        Credentials
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        defaultValue={state.card_email}
                                                        required
                                                        fullWidth
                                                        id="email"
                                                        label="Email Address (Fetched from Capillary)"
                                                        name="email"
                                                        autoComplete="email"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        defaultValue="08077043411"
                                                        id="mobile"
                                                        label="Mobile Number (Fetched from Capillary)"
                                                        name="mobile"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        type='password'
                                                        defaultValue="Tra!nme4321"
                                                        id="password"
                                                        label="Password"
                                                        name="password"
                                                    />
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={12}>
                                            {
                                                state.currentStep === Steps.SIGNUP ?
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mb: 2 }}
                                                    >
                                                        Continue
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        sx={{ mb: 2 }}
                                                    >
                                                        Sign Up
                                                    </Button>
                                            }
                                        </Grid>

                                    </Grid>

                                </Box>
                            </Paper>
                        </Grid>

                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Button
                                fullWidth
                                variant="text"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={() => dispatchAction(ActionTypes.SHOW_LOGIN)}
                            >
                                Already have an account? Sign in
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Container>
        </ThemeProvider >
    );
}