import axios from 'axios';
const config = require('../config.json')

export default class MiddlewareClient {
    private state: string = "";
    private sessionToken: string = "";

    constructor() { }

    async lookupCardInfo(cardNumber: string, firstName: string, lastName: string, dob: string) {
        // const { data } = await axios.post(`${config.CAPILLARY_MIDDLEWARE_BASE_URL}/api/public/capillary/lookup/card`, {
        //     cardNumber, firstName, lastName, dob
        // })
        const result = [cardNumber, firstName, lastName, dob].every(p => !p || !p.includes("invalid"));
        if (result) {
            return ({
                result: true,
                user: {
                    address: "1-2-3",
                    email: "john+card@gmail.com",
                    mobile: "+8108077043411",
                    katakana_sei: "タナカ",
                    katakana_mei: "タロウ",
                }
            })
        } else {
            return ({
                result: false
            })
        }
    }
}